import { FieldType, MortgageQuestion } from "src/api";

const questions: MortgageQuestion[] = [
  {
    key: "Q1a",
    text: "Sparande",
    field: "downPaymentFromSavings",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q1b",
    text: "Bostadsförsäljning",
    field: "downPaymentFromHomeSale",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q1c",
    text: "Arv",
    field: "downPaymentFromHeritage",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q1d",
    text: "Gåva",
    field: "downPaymentFromGift",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q1e",
    text: "Lån",
    field: "downPaymentFromLoan",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q1f",
    text: "Övrigt",
    field: "downPaymentFromOther",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q2",
    text: "Hur länge har du sparat?",
    field: "downPaymentFromSavingsDuration",
    type: FieldType.Select,
    options: [
      "3 mån",
      "6 mån",
      "1 år",
      "2 år",
      "3 år",
      "4 år",
      "5 år",
      "6 år",
      "7 år",
      "8 år",
      "9 år",
      "10 år",
      "Mer än 10 år"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q3",
    text: "Hur mycket sparar du i månaden?",
    field: "downPaymentFromSavingsMonthlyRange",
    type: FieldType.Select,
    options: [
      "1 000 - 2 000 kr",
      "2 000 - 3 000 kr",
      "3 000 - 5 000 kr",
      "5 000 - 7 000 kr",
      "7 000 - 9 000 kr",
      "9 000 - 11 000 kr",
      "11 000 - 13 000 kr",
      "13 000 - 15 000 kr",
      "Mer än 15 000 kr"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q4",
    text: "Var kommer ditt sparande från?",
    field: "downPaymentFromSavingsSource",
    type: FieldType.MultiSelect,
    options: [
      "Lön",
      "Studiemedel",
      "Vinstutdelningar",
      "Vinster från aktier och fonder",
      "Bidrag",
      "Tidigare bostadsförsäljningar",
      "Gåvor",
      "Pension"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q5",
    text: "Vad har du för boendekostnader idag?",
    field: "housingCostRange",
    type: FieldType.Select,
    options: [
      "1 000 - 2 000 kr",
      "2 000 - 3 000 kr",
      "3 000 - 5 000 kr",
      "5 000 - 7 000 kr",
      "7 000 - 9 000 kr",
      "9 000 - 11 000 kr",
      "11 000 - 13 000 kr",
      "13 000 - 15 000 kr",
      "Mer än 15 000 kr"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q6",
    text: "Hur mycket har du sålt eller kommer du att sälja din bostad för?",
    field: "propertySellingPrice",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q6a",
    text: "Behöver du hjälp att hitta en mäklare?",
    field: "helpWithBroker",
    type: FieldType.Boolean,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q7",
    text: "Vad köpte du bostaden för?",
    field: "propertyStartingPrice",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q8",
    text: "Ska du göra uppskov?",
    field: "deferProfitTax",
    type: FieldType.Boolean,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: "Uppskov innebär att du skjuter upp vinstbeskattningen."
  },
  {
    key: "Q9",
    text: "Hur stort bolån har eller hade du på bostaden?",
    field: "existingLoanAmount",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q10",
    text: "Vem kommer arvet från?",
    field: "downPaymentFromHeritageSource",
    type: FieldType.MultiSelect,
    options: [
      "Förälder",
      "Far-/morförälder",
      "Annan släktning",
      "Annan familjemedlem"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q11",
    text: "Vem kommer gåvan från?",
    field: "downPaymentFromGiftPerson",
    type: FieldType.MultiSelect,
    options: [
      "Förälder",
      "Far-/morförälder",
      "Syskon",
      "Annan släktning",
      "Partner",
      "Vän",
      "Annan person"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q12",
    text: "Vad har gåvogivaren för sysselsättning?",
    field: "downPaymentFromGiftPersonEmployment",
    type: FieldType.Select,
    options: [
      "Anställd",
      "Pensionär",
      "Arbetslös",
      "Studerande",
      "Egen företagare",
      "Annan sysselsättning"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q13",
    text: "Hur har gåvogivaren sparat ihop pengarna? Genom:",
    field: "downPaymentFromGiftSource",
    type: FieldType.MultiSelect,
    options: [
      "Lön",
      "Studiemedel",
      "Vinstutdelningar",
      "Vinster från aktier och fonder",
      "Bidrag",
      "Bostadsförsäljningar",
      "Arv",
      "Pension"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q14",
    text: "Vem lånar du pengarna av?",
    field: "downPaymentFromLoanSource",
    type: FieldType.MultiSelect,
    options: [
      "Bank",
      "Förälder",
      "Far-/morförälder",
      "Syskon",
      "Annan släktning",
      "Partner",
      "Vän",
      "Annan person"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q15",
    text: "Vad kommer lånet kosta varje månad?",
    field: "downPaymentFromLoanMonthlyCost",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q16",
    text: "Vad ligger räntan på?",
    field: "downPaymentFromLoanInterestRate",
    type: FieldType.Percent,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q18",
    text: "Vad har personen som lånar ut pengar till dig för sysselsättning?",
    field: "downPaymentFromLoanLenderEmployment",
    type: FieldType.Select,
    options: [
      "Anställd",
      "Pensionär",
      "Arbetslös",
      "Studerande",
      "Egen företagare",
      "Annan sysselsättning"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q19",
    text: "Hur har långivaren sparat ihop pengarna? Genom:",
    field: "downPaymentFromLoanLenderSource",
    type: FieldType.MultiSelect,
    options: [
      "Lön",
      "Studiemedel",
      "Vinstutdelningar",
      "Vinster från aktier och fonder",
      "Bidrag",
      "Bostadsförsäljningar",
      "Arv",
      "Pension"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q21",
    text: "Beskriv i rutan nedan vart kontantinsatsen kommer från?",
    field: "downPaymentFromOtherSource",
    type: FieldType.Textarea,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q22",
    text: "Har du några andra inkomster utöver den uppgivna i ansökan?",
    field: "hasOtherIncome",
    type: FieldType.Boolean,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q23",
    text: "Vart kommer dessa inkomster från?",
    field: "otherIncomeSource",
    type: FieldType.MultiSelect,
    options: [
      "Extra jobb",
      "Bidrag",
      "Underhållsstöd",
      "Studiemedel",
      "Övrigt"
    ],
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q24",
    text: "Hur mycket i inkomst får du från detta?",
    field: "otherIncomeBeforeTax",
    type: FieldType.Money,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q25",
    text: "Vad har du för yrkesroll idag?",
    field: "profession",
    type: FieldType.Textarea,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 1,
    tooltip: null
  },
  {
    key: "Q26",
    text: "Har du en högskoleutbildning, yrkesutbildning eller motsvarande kopplat till ditt yrke?",
    field: "hasProfessionalDegree",
    type: FieldType.Boolean,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q27",
    text: "Hur lång erfarenhet har du inom yrkesområdet?",
    field: "professionalExperience",
    type: FieldType.Select,
    options: [
      "Mindre än 1 år",
      "1 - 2 år",
      "2 - 3 år",
      "3 - 5 år",
      "Mer än 5 år"
    ],
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q28",
    text: "Vad förväntas ditt företag omsätta i år?",
    field: "organizationAnnualTurnover",
    type: FieldType.Money,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q28b",
    text: "Beskriv hur denna omsättning kommer uppnås",
    field: "organizationAnnualTurnoverDescription",
    type: FieldType.Textarea,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q29",
    text: "Hur många anställda har bolaget?",
    field: "organizationNumberOfEmployees",
    type: FieldType.Number,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q30",
    text: "Hur mycket uppskattar du att din utdelning blir för innevarande räkenskapsår?",
    field: "expectedDividend",
    type: FieldType.Money,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q31",
    text: "Hur mycket kommer du ha i inkomst före skatt när du blir ålderspensionerad?",
    field: "incomeBeforeTaxWhenRetired",
    type: FieldType.Money,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q32",
    text: "Är du ansluten till a-kassa?",
    field: "hasUnemploymentFund",
    type: FieldType.Boolean,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q33",
    text: "Hur mycket har du i garantilön före skatt?",
    field: "guaranteedIncomeBeforeTax",
    type: FieldType.Money,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q34",
    text: "Din uppgivna inkomst avviker mot inkomstår {year}, vad beror den avvikelsen på?",
    field: "incomeDeviationReason",
    type: FieldType.MultiSelect,
    options: [
      "Studier",
      "Bytt tjänst",
      "Löneförhöjning",
      "Föräldraledighet",
      "Sjukskrivning",
      "Semester"
    ],
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q34b",
    text: "Din uppgivna inkomst avviker mot inkomstår {year}, vad beror den avvikelsen på?",
    field: "incomeDeviationReason2",
    type: FieldType.MultiSelect,
    options: [
      "Studier",
      "Bytt tjänst",
      "Löneförhöjning",
      "Föräldraledighet",
      "Sjukskrivning",
      "Semester"
    ],
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q35",
    text: "Kommer du behöva ett handpenningslån?",
    field: "needsDownPaymentLoan",
    type: FieldType.Boolean,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip:
      "Handpenningslån brukar man ta om man har sin kontantinsats låst i bostaden som ska säljas. Handpenningslånet är upp till 10% av priset på den nya bostaden och ska betalas tillbaka på tillträdesdagen."
  },
  {
    key: "Q36",
    text: "Hur mycket kommer du behöva i handpenningslån?",
    field: "needsDownPaymentLoanAmount",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q37",
    text: "Kommer du behöva ett överbryggningslån?",
    field: "needsBridgeLoan",
    type: FieldType.Boolean,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip:
      "En överbryggningskredit eller överbryggningslån är ett lån som används när man tillfälligt har två bostäder. Situationen dyker ofta upp när man har ett tillträde innan ett frånträde. Lånet används för att betala den nya bostaden när du saknar finansiering, då pengarna kanske är låsta i den gamla bostaden."
  },
  {
    key: "Q38",
    text: "Hur mycket kommer du behöva i överbryggningslån?",
    field: "bridgeLoanAmount",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  // {
  //   key: "Q39",
  //   text: "Har du signerat kontrakt för en ny bostad?",
  //   field: "sellingContractSigned",
  //   type: FieldType.Boolean,
  //   options: null,
  //   forApplicant: false,
  //   forPrivateLoans: false,
  //   forOwnedCars: false,
  //   forHousing: false,
  //   isCollection: false,
  //   rows: 0,
  //   tooltip: null
  // },
  {
    key: "Q39",
    text: "Hur långt har du kommit i köpet?",
    field: "howFarInPurchaseProcess",
    type: FieldType.Select,
    options: [
      "Jag ska lägga bud på en bostad",
      "Jag har lagt bud på en bostad",
      "Jag har vunnit budgivningen",
      "Jag ska skriva kontrakt",
      "Jag har skrivit kontrakt",
      "Jag ska på visning",
      "Jag ska börja leta efter en bostad"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q40",
    text: "När är tillträdesdagen?",
    field: "entryDate",
    type: FieldType.Date,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  // {
  //   key: "Q41",
  //   text: "Hur mycket amorterar du månadsvis på ditt lån / din kredit?",
  //   field: "amortizationAmount",
  //   type: FieldType.Money,
  //   options: null,
  //   forApplicant: false,
  //   forPrivateLoans: true,
  //   forOwnedCars: false,
  //   forHousing: false,
  //   isCollection: false,
  //   rows: 0,
  //   tooltip: null
  // },
  // {
  //   key: "Q42",
  //   text: "Vad har du för ränta på ditt lån/kredit?",
  //   field: "interestRate",
  //   type: FieldType.Percent,
  //   options: null,
  //   forApplicant: false,
  //   forPrivateLoans: true,
  //   forOwnedCars: false,
  //   forHousing: false,
  //   isCollection: false,
  //   rows: 0,
  //   tooltip: null
  // },
  {
    key: "Q45",
    text: "Hur mycket amorterar du varje månad?",
    field: "propertyAmortizationAmount",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q46",
    text: "Vad har du för ränta på ditt bolån?",
    field: "propertyInterestRate",
    type: FieldType.Percent,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q47",
    text: "Har du bunden eller rörlig ränta?",
    field: "propertyInterestRateType",
    type: FieldType.Select,
    options: ["Bunden", "Rörlig", "Båda"],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q48",
    text: "Hur stor ägarandel har du i bostaden? Ange 0 - 100%.",
    field: "propertyOwnerShipShare",
    type: FieldType.Percent,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q49",
    text: "Vad är ditt lägenhetsnummer? Ange numret som föreningen använder (ej Skatteverkets).",
    field: "propertyNumber",
    type: FieldType.Text,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q50",
    text: "Har lägenheten balkong eller altan?",
    field: "propertyBalconyType",
    type: FieldType.Select,
    options: ["Ja, balkong", "Nej", "Ja, altan"],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q51",
    text: "När tog du bolånet?",
    field: "homeMortgageDate",
    type: FieldType.Select,
    options: [
      "Innan juni 2016",
      "Efter juni 2016 men innan mars 2018",
      "Efter mars 2018"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q52",
    text: "Finns det hiss i huset?",
    field: "propertyHasElevator",
    type: FieldType.Boolean,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q53",
    text: "Ange fastighetsbeteckningen.",
    field: "propertyCadastralDesignation",
    type: FieldType.Text,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q54",
    text: "Vad är syftet med ansökan?",
    field: "renegotiateLoanPurpose",
    type: FieldType.MultiSelect,
    options: [
      "Sänka bolåneräntan",
      "Sänka amorteringstakten",
      "Utöka för att renovera",
      "Utöka i annat syfte",
      "Lösa privatlån/krediter",
      "Lösa andra skulder",
      "Övrigt"
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q55",
    text: "Har ni några bilar i hushållet? (Ej bilar som det finns billån på)?",
    field: "carsInHousehold",
    type: FieldType.Boolean,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q56",
    text: "Privatägd eller privatleasing?",
    field: "carOwnType",
    type: FieldType.Select,
    options: ["Privatleasing", "Privatägd"],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: true,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q56a",
    text: "Leasingkostnad per månad",
    field: "monthlyCost",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: true,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q57",
    text: "Har du borgen för någon annans lån?",
    field: "isLoanGuarantor",
    type: FieldType.Boolean,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q59",
    text: "Hur mycket har du i bolån på bostaden?",
    field: "amount",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: true,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  // {
  //   key: "Q60",
  //   text: "Vad har du för ränta på ditt bolån?",
  //   field: "interestRate",
  //   type: FieldType.Percent,
  //   options: null,
  //   forApplicant: false,
  //   forPrivateLoans: false,
  //   forOwnedCars: false,
  //   forHousing: true,
  //   isCollection: false,
  //   rows: 0,
  //   tooltip: null
  // },
  {
    key: "Q61",
    text: "Hur mycket amorterar du varje månad?",
    field: "amortizationAmount",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: true,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q62",
    text: "Hur stor ägarandel har du i bostaden? Ange från 0 - 100?",
    field: "ownerShipShare",
    type: FieldType.Percent,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: true,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q63",
    text: "Har du tjänstepension hos Skandia? I sådant fall får du ränterabatt på bolånet.",
    field: "totalSavingsInSkandia",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q64",
    text: "Behöver du låna till kontantinsatsen",
    field: "needsToBorrowDownPayment",
    type: FieldType.Boolean,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q65",
    text: "Ange beloppet du behöver låna",
    field: "downPaymentToBorrow",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q66",
    text: "Hur mycket har du i bolån idag?",
    field: "currentMortgageLoanAmount",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q67",
    text: "Vilken bank har du idag?",
    field: "currentMortgageBank",
    type: FieldType.Select,
    descriptionsText: "Behöver du hjälp med att hitta amorteringsunderlag?",
    options: [
      "Bluestep Bank",
      "Danske Bank",
      "Handelsbanken",
      "Hypoteket",
      "ICA Banken",
      "Ikano Bank",
      "Landshypotek",
      "Länsförsäkringar",
      "Marginalen Bank",
      "Nordax Bank",
      "Nordea",
      "SBAB",
      "SEB",
      "Skandia",
      "Sparbankerna",
      "Stabelo",
      "Svea Ekonomi",
      "Swedbank",
      "Ålandsbanken"
    ],
    descriptions: [
      "Ring banken på telefonnummer 0770-18 88 07.",
      "Skriv ett meddelande till banken via chatten på hemsidan.",
      "Skicka ett mejl via E-brevlådan i internetbanken till mottagare 'Kontorets brevlåda', dvs ditt lokala kontor.",
      "Mejla banken på kontakt@hypoteket.com.",
      "Ring banken på telefonnummer 033-47 47 90.",
      "Ring banken på telefonnummer 0476-88 000.",
      "Ring banken på telefonnummer 0476-88 000.",
      "Logga in på internetbanken och skicka ett meddelande, alternativt ring på telefonnummer 0771 44 00 20.",
      "Logga in på internetbanken och skicka ett meddelande.",
      "Ring banken på telefonnummer 08-508 808 00.",
      "Logga in på internetbanken och skicka ett meddelande, alternativt ring på telefonnummer 0771-122 44 88.",
      "Logga in på internetbanken och hämta amorteringsunderlag digitalt.",
      "Logga in på internetbanken och skicka ett meddelande, alternativt ring banken på telefonnummer 0771-365 365.",
      "Skriv ett meddelande till banken via chatten på hemsidan, alternativt kontakta banken på telefonnummer 0771-55 55 00.",
      "Ring banken på telefonnummer 0771-22 11 22.",
      "Ring banken på telefonnummer 0923-68880 och uppge personnummer eller lånenummer.",
      "Ring banken på telefonnummer 08 735 90 00.",
      "Ring banken på telefonnummer 0771-22 11 22.",
      "Ring banken på telefonnummer 0771 415 415."
    ],
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q68",
    text: "Hur mycket kommer du ha i inkomst när du går i pension?",
    field: "incomeAfterRetirement",
    type: FieldType.Money,
    options: null,
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q69",
    text: "Har du sålt din bostad?",
    field: "currentPropertyHasBeenSold",
    type: FieldType.Boolean,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q70",
    text: "Hur mycket har din partner i månadsinkomst före skatt?",
    field: "partnerMonthlyIncome",
    type: FieldType.Money,
    options: null,
    forApplicant: false,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
  {
    key: "Q71",
    text: "Hur länge har du tagit ut uppgiven inkomst?",
    field: "incomeDuration",
    type: FieldType.Select,
    options: [
      "1 mån",
      "2 mån",
      "3 mån",
      "4 mån",
      "5 mån",
      "6 mån",
      "7 mån",
      "8 mån",
      "9 mån",
      "10 mån",
      "11 mån",
      "1 år",
      "2 år",
      "Mer än 2 år"
    ],
    forApplicant: true,
    forPrivateLoans: false,
    forOwnedCars: false,
    forHousing: false,
    isCollection: false,
    rows: 0,
    tooltip: null
  },
];

export default questions;
