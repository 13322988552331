
import { defineComponent, PropType } from "vue";
import { MortgageQuestion } from "src/api";
import { getQuestionValue } from "src/model/mortgageQuestions";
import Tooltip from "src/components/Controls/Tooltip.vue";
import RadioInput from "src/components/Controls/RadioInput.vue";
import YesNoInput from "src/components/Controls/YesNoInput.vue";
import SelectInput from "src/components/Controls/SelectInput.vue";
import MultiSelectInput from "src/components/Controls/MultiSelectInput.vue";
import NumberInput from "@/components/Controls/NumberInput.vue";
import CheckboxInput from "src/components/Controls/CheckboxInput.vue";
import TextInput from "src/components/Controls/TextInput.vue";
import TextareaInput from "src/components/Controls/TextareaInput.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
  components: {
    Tooltip,
    RadioInput,
    SelectInput,
    MultiSelectInput,
    YesNoInput,
    NumberInput,
    CheckboxInput,
    TextInput,
    TextareaInput,
    Datepicker
  },
  props: {
    showIndex: { type: Boolean, default: false },
    questions: {
      type: Object as PropType<MortgageQuestion[]>,
      default: []
    },
    form: {} as any,
    isCoApplicant: { type: Boolean, default: false },
    formIndex: { type: Number, default: null }
  },
  methods: {
    getQuestionComponent(question: MortgageQuestion) {
      switch (question.type) {
        case "Money":
        case "Percent":
        case "Number":
          return NumberInput;
        case "Select":
          return SelectInput;
        case "MultiSelect":
          return MultiSelectInput;
        case "Boolean":
          return YesNoInput;
        case "Textarea":
          return TextareaInput;
        case "Text":
          return TextInput;
        default:
          return null;
      }
    },
    getQuestionComponentProps(question: MortgageQuestion) {
      switch (question.type) {
        case "Money":
          return { money: true };
        case "Percent":
          return { percent: true };
        case "Select":
        case "MultiSelect":
          return { options: question.options };
        case "Boolean":
          return { tooltip: question.tooltip };
        case "Textarea":
          return { rows: question.rows || 3 };
        default:
          return {};
      }
    },
    getValue(question: MortgageQuestion) {
      return getQuestionValue(
        question,
        this.form,
        this.isCoApplicant,
        this.formIndex
      );
    },
    onUpdate(question: MortgageQuestion, value: any) {
      this.$emit("update:question", value, question, this.formIndex);
    }
  }
});
