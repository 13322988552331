
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    username: { type: String, required: false },
    showMenu: { type: Boolean, default: false }
  },
  methods: {
    onToggleMenu() {
      this.$emit("toggleMenu");
    }
  }
});
