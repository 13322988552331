
import { defineComponent } from "vue";
import { api, AccountInfo } from "src/api";
import Tooltip from "src/components/Controls/Tooltip.vue";
import SelectInput from "src/components/Controls/SelectInput.vue";
import NumberInput from "src/components/Controls/NumberInput.vue";

let formIdSequence = 0;

export default defineComponent({
  components: {
    Tooltip,
    SelectInput,
    NumberInput
  },
  emits: ["update"],
  data() {
    return {
      bankCodeValues: {} as { [key: string]: string },
      form: {} as AccountInfo,
      formId: formIdSequence++
    };
  },
  watch: {
    form: {
      handler() {
        this.$emit("update", { ...this.form });
      },
      deep: true
    }
  },
  mounted: async function () {
    try {
      this.bankCodeValues = (await api.Loan.getBankCodes()).data;
    } catch (e) {}
  },
});
