import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-label d-block" }
const _hoisted_2 = { class: "form-check form-check-inline" }
const _hoisted_3 = ["id", "checked"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "form-check form-check-inline" }
const _hoisted_6 = ["id", "checked"]
const _hoisted_7 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        id: `yesnoinput${_ctx.elementId}_yes`,
        type: "radio",
        checked: _ctx.modelValue == true,
        class: "form-check-input",
        onInput: _cache[0] || (_cache[0] = (ev) => _ctx.$emit('update:modelValue', true))
      }, null, 40, _hoisted_3),
      _createElementVNode("label", {
        for: `yesnoinput${_ctx.elementId}_yes`,
        class: "form-check-label"
      }, " Ja ", 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("input", {
        id: `yesnoinput${_ctx.elementId}_no`,
        type: "radio",
        checked: _ctx.modelValue == false,
        class: "form-check-input",
        onInput: _cache[1] || (_cache[1] = (ev) => _ctx.$emit('update:modelValue', false))
      }, null, 40, _hoisted_6),
      _createElementVNode("label", {
        for: `yesnoinput${_ctx.elementId}_no`,
        class: "form-check-label"
      }, " Nej ", 8, _hoisted_7),
      (_ctx.tooltip)
        ? (_openBlock(), _createBlock(_component_Tooltip, {
            key: 0,
            title: _ctx.tooltip
          }, null, 8, ["title"]))
        : _createCommentVNode("", true)
    ])
  ]))
}